import "./App.css";
import datadog from "./assets/1200px-Datadog_logo.svg.png";
import intuit from "./assets/intuit-squarelogo-1573086631128.png";
import alula from "./assets/alula-logo-for-geoarm-security-home.jpg";
import nasa from "./assets/nasa.jpg";
import jose from "./assets/jose.jpg";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="App-header">
          <img
            className="ProfilePic"
            src={jose}
            alt="Intuit"
            width="90"
            height="90"
          />
          <h2 className="App-hTag App-name">Jose Gonzalez</h2>
          <h4 className="App-softwareEngineer App-hTag">Software Engineer</h4>
          <div>
            <nav className="App-navbar">
              <Link className="App-link" to="/about">
                <h5 className="App-hTag">About</h5>
              </Link>

              <Link className="App-link" to="/about">
                <h5 className="App-hTag">Projects (coming soon) </h5>
              </Link>
              <a className="App-link" href="https://github.com/JoseGonzalez212">
                <h5 className="App-hTag">Github</h5>
              </a>
              <a
                className="App-link"
                href="https://www.linkedin.com/in/jose-gonzalez-05a597124/"
              >
                <h5 className="App-hTag">LinkedIn</h5>
              </a>
            </nav>
          </div>

          <Switch>
            <Route exact path="/about">
              <div className="App-About">
                <div className="App-Timeline-Item">
                  <div className="App-Timeline-Item-TitleCompany">
                    <img src={datadog} alt="Datadog" width="70" height="70" />
                    <div className="App-Timeline-Item-TitleCompany-Details">
                      <h5 className="App-hTag">
                        Software Engineer - New York City, NY
                      </h5>
                      <h6 className="App-hTag">Jan 2021 - Present</h6>
                    </div>
                  </div>
                  <div className="App-Timeline-Item-TitleCompany">
                    <img src={intuit} alt="Intuit" width="70" height="70" />
                    <div className="App-Timeline-Item-TitleCompany-Details">
                      <h5 className="App-hTag">
                        Software Engineer Intern - Mountain View, CA
                      </h5>
                      <h6 className="App-hTag">May 2020 - Aug 2020</h6>
                    </div>
                  </div>
                  <div className="App-Timeline-Item-TitleCompany">
                    <img src={alula} alt="Datadog" width="70" height="70" />
                    <div className="App-Timeline-Item-TitleCompany-Details">
                      <h5 className="App-hTag">
                        Software Engineer Intern - Sugarland, TX
                      </h5>
                      <h6 className="App-hTag">Sept 2019 - May 2020</h6>
                    </div>
                  </div>
                  <div className="App-Timeline-Item-TitleCompany">
                    <img src={nasa} alt="Datadog" width="70" height="70" />
                    <div className="App-Timeline-Item-TitleCompany-Details">
                      <h5 className="App-hTag">
                        Web Developer Intern - Houston, TX
                      </h5>
                      <h6 className="App-hTag">Nov 2018 - Aug 2019</h6>
                    </div>
                  </div>
                </div>
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
